import {mapActions, mapGetters, mapMutations} from "vuex";
import {email, required} from "vuelidate/lib/validators";
import {validationMixin} from "vuelidate";

export default {
  name: "delivery-list",
  data() {
    return {
      currentDelivery: {},
    }
  },
  components: {},
  computed: {
    ...mapGetters({
      // deliveryList: `delivery/deliveryMethodsList`,
      deliveryList: `payment/deliveryList`,
      deliveryInfo: `delivery/deliveryInfo`,
      deliveryLoading:`delivery/deliveryMethodsListLoading`,
      currentCurrency:`setting/currentCurrency`
    }),
  },
  created() {
    // this.fetchDeliveryList()
    this.setData()

  },
  methods: {
    ...mapMutations({
      changeDeliveryInfo: `delivery/CHANGE_DELIVERY_INFO`,
      changeDeliveryListPopup: `order/CHANGE_POPUP_DELIVERY`,
      changeStepDelivery: `order/CHANGE_STEP_DELIVERY`,
    }),
    ...mapActions({
      fetchDeliveryList: `delivery/GET_DELIVERY_METHODS_LIST`
    }),
    setData() {
      Object.assign(this.currentDelivery,this.deliveryInfo)
    },
    save() {

      this.changeDeliveryInfo(this.currentDelivery)
      this.changeDeliveryListPopup(false)
      this.changeStepDelivery(true)

    }
  }
}